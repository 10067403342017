import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import globalTheme from "global/theme"

const styles = theme => ({
  footer: {
    backgroundColor: globalTheme.color.primary,
  },
  email: {
    display: 'flex',
    flexDirection: 'row',
  }
})

const FreeBanner = ({classes, t}) => (
  <footer className={classes.footer}>
    <Typography variant="h6" align="center" gutterBottom>
    {t('free')}
    </Typography>
  </footer>
)

export default compose(
  withStyles(styles),
  withTranslation(),
)(FreeBanner)