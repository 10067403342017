import React from 'react';
import { withStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import AddIcon from '@material-ui/icons/Add'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  title: {
    margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 1}px`,
  },
  demo: {
    flexGrow: 1,
    margin: 'auto',
    maxWidth: 520,
  }
})

const items = (t) =>  {
  return [
  {
    primary: t('components.split.primary'),
    secondary: t('components.split.secondary'),
    icon: <AddIcon />,
  },
  {
    primary: t('components.deposit.primary'),
    secondary: t('components.deposit.secondary'),
    icon: <AddIcon />,
  },
  {
    primary: t('components.hotel.primary'),
    secondary: t('components.hotel.secondary'),
    icon: <AddIcon />,
  },
  {
    primary: t('components.instructor.primary'),
    secondary: t('components.instructor.secondary'),
    icon: <AddIcon />,
  },
  {
    primary: t('components.documents.primary'),
    secondary: t('components.documents.secondary'),
    icon: <AddIcon />,
  },
  {
    primary: t('components.reservation.primary'),
    secondary: t('components.reservation.secondary'),
    icon: <AddIcon />,
  }
]
}
const ListComponent = ({ classes, t }) => (
  <React.Fragment>
  {/* <Typography variant="h6" align="center" className={classes.title}>
    And more ....
  </Typography> */}
    <div className={classes.demo}>
      <List>
          { items(t).map(item => (
            <ListItem>
            <ListItemIcon>
               {item.icon}
            </ListItemIcon>
            <ListItemText
              primary={item.primary}
              secondary={item.secondary}
            />
          </ListItem>
          ))}
      </List>
    </div>
  </React.Fragment>
)

export default compose(
  withStyles(styles),
  withTranslation()
)(ListComponent)
