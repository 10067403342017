import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  heroUnit: { },
  heroContent: {
    maxWidth: 600,
    margin: '0 auto',
    padding: `${theme.spacing.unit * 1}px 0 ${theme.spacing.unit * 4}px`,
  },
})

const NavBar = ({ classes, t }) => (
  <div className={classes.heroUnit}>
    <div className={classes.heroContent}>
      <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
        runschool.eu
      </Typography>
      <Typography variant="h6" align="center" color="textSecondary" paragraph>
        {t('subtitle')}
      </Typography>
    </div>
  </div>
)

export default compose(
  withStyles(styles),
  withTranslation()
 )(NavBar)



