import React from 'react';
import Card1 from './Card1'
import Card2 from './Card2'
import Card3 from './Card3'
import theme from "global/theme"

import { withStyles } from '@material-ui/core/styles'

const styles = theme2 => ({
  cards: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    backgroundColor: theme.color.newLight,
    padding: `${theme2.spacing.unit * 4}px 0 ${theme2.spacing.unit * 3}px`,
    alignItems: 'center'
  },
  '@media (min-width: 960px)': {
    cards: {
      flexDirection: 'row',
      alignItems: 'normal'
    },
  },
})

const Cards = ({classes}) => (
   <div className={classes.cards}>
     <Card1 />
     <Card2 />
     <Card3 />
   </div>
)

export default withStyles(styles)(Cards)
