import React from 'react'
import MainText from 'components/MainText'
import Cards from 'components/Cards'
import PricingCards from 'components/PricingCards'
import Footer from 'components/Footer'
import ListComponent from 'components/ListComponent'
import LanguageBar from 'components/LanguageBar'

const App = () => (
    <React.Fragment>
      <LanguageBar />
      <MainText/>
      <Cards />
      <ListComponent />
      <PricingCards />
      <Footer />
    </React.Fragment>
)

export default App
