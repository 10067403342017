const lighten = (hex, alpha) =>
  `rgba(${parseInt(hex.substring(1, 3), 16)},${parseInt(
    hex.substring(3, 5),
    16,
  )},${parseInt(hex.substring(5, 7), 16)},${alpha})`

const theme = {
  lighten,
  color: {
    newSomegray: "#7E909A",
    newPrimary: "#1C4E80",
    newBlack: "#000f32",
    newGray: "#7E909A",
    newLight: "#F1F1F1",
    newSuperlight: "#f9fafb",
    danger: "#ff5500",
    success: "#00d09a",
    shadow: "#f1f1f7",

    primary: "#0091D5",
    primaryLight: `rgba(0, 145, 213, .2)`,
    redLight: `rgba(234, 106, 71, .2)`,
    primaryDark: "#1C4E80",
    black: "#000000",
    dark: "#323D55",
    gray: "#adb5c4",
    lightGray: "#d9dee6",
    light: "#f6f8f9",
    white: "#ffffff",
    red: "#ec4833",
    green: "#0acd8e",
    yellow: "#ffa900",
  },
  zIndices: {
    minimal: 1,
    overlayOverlay: 98,
    overlayContent: 99,
    overlay: 100,
    header: 100,
    leftSidebar: 50,
    modal: 1000,
    notification: 10000,
  },
  fontWeights: {
    normal: 400,
    book: 500,
    medium: 600,
    bold: 700,
    ultra: 900,
  },
}

export default theme
