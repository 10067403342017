import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = {
  card: {
    maxWidth: 345,
    marginTop: '0.5rem',
  },
  media: {
    height: 140,
  },
  '@media (min-width: 960px)': {
    card: {
      marginTop: '0rem'
    },
  },
};

const MediaCard = ({classes, t}) => (
    <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image="calendar02.png"
          title="Calendar"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            Booker
          </Typography>
          <Typography component="p">
            {t('cards.bookerDesc')}
          </Typography>
        </CardContent>
    </Card>
)

export default compose(
  withStyles(styles),
  withTranslation(),
)(MediaCard)