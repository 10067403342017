import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // bind react-i18next to the instance
  .init({
    debug: true,
    interpolation: {
      // React already does escaping
      escapeValue: false,
    },
    fallbackLng: 'cs',
    whitelist: [ 'cs', 'en'],
    // Using simple hardcoded resources for simple example
    resources: {
      en: {
        translation: {
          subtitle: 'Ski school software',
          free: 'Free of charge to October 2019!',
          cards: {
            instructor: 'Instructor',
            instructorDesc1: 'Set your availability, list past and upcoming lessons and manage your earnings.',
            instructorDesc2: 'Connect from anywhere to create a new booking on a slope.',
            bookerDesc: ' Manage all lessons and reservations in drag and drop calendar.',
            manager: 'Manager',
            managerDesc1: 'Customize a school according to your needs.',
            managerDesc2: 'Create new accounts with proper roles and view everything in well-arranged reports.'
          },
          components: {
            split: {
              primary: 'Split payments',
              secondary: 'Multi lessons orders can be paid per lesson',
            },
            deposit: {
              primary: 'Deposit payments',
              secondary: 'Add deposit payments to orders.',
            },
            hotel: {
              primary: 'Hotels and resorts',
              secondary: 'Hotels can create a reservations for their customers via runschool.',
            },
            instructor: {
              primary: 'Instructor commissions',
              secondary: 'Calculate commissions according to your customized settings.',
            },
            documents: {
              primary: 'Legal documents',
              secondary: 'Have all necessary documents for your instructors at one place.',
            },
            reservation: {
              primary: 'Quick reservation creation',
              secondary: 'Save your time and create reservation fast.',
            },
          },
          pricing: {
            basic: 'Basic',
            row1: 'unlimited bookings',
            row2: 'unlimited instructors',
            row3: 'access for 3rd party hotels',
            month: 'month',
          },
          footer: {
            header: 'Request beta access',
            line1: 'Currently we are in beta to test everything and go live on November 2019.',
            line2: 'We are happy to create a new access to our beta version.',
            line3: 'Please contact us on '
          }
        },
      },
      cs: {
        translation: {
          subtitle: 'Software pro správu lyžarské školy',
          free: 'Zdarma do října 2019!',
          cards: {
            instructor: 'Instruktor',
            instructorDesc1: 'V rozhraní pro mobilní telefony může každý instruktor prcházet svoje objednávky.',
            instructorDesc2: 'Instruktor se může přijojit odkuliv a vytvořit objednávku rovnou na sjezdovce.',
            bookerDesc: 'Jednoduché vytvoření a správa objednávek v drag and drop kalendáři.',
            manager: 'Manažer',
            managerDesc1: 'Každá škola si může nastavit systém podle svých potřeb.',
            managerDesc2: 'Správa účtů a jejich rolí. Zobrazení statistik v přehledných reportech.'
          },
          components: {
            split: {
              primary: 'Rozdělené platby',
              secondary: 'Vícehodinnové objednávky lze platit po odučených hodinách.',
            },
            deposit: {
              primary: 'Zálohy',
              secondary: 'Možnost zadávání záloh k objednávkám.',
            },
            hotel: {
              primary: 'Hotely a jiná ubytování',
              secondary: 'Vaše spřátelné hotely mohou zadávat požadavky na objednávku.',
            },
            instructor: {
              primary: 'Provize instruktorům',
              secondary: 'Provize instruktorům se počítá podle vašeho nastavení',
            },
            documents: {
              primary: 'Dokumenty',
              secondary: 'Mějte všechny dokumenty pro instruktory na jednom místě.',
            },
            reservation: {
              primary: 'Rychlá tvorba objednávek',
              secondary: 'Ušetřete čas a jednoduše vytvářejte objednávky.',
            },
          },
          pricing: {
            basic: 'Základní',
            row1: 'neomezeně objednávek',
            row2: 'neomezeně instruktorů',
            row3: 'přístup pro hotely',
            month: 'měsíc',
          },
          footer: {
            header: 'Napište nám',
            line1: 'Aktuálně jsme v testovacím režimu.',
            line2: 'Velice rádi vám vytvoříme přihlašovací údaje ať můžete zdarma vyzkoušet náš systém.',
            line3: 'Neváhejte nás kontaktovat na '
          }
        },
      },
    },
    react: {
      bindI18n: 'languageChanged',
    }    
  })

export default i18n