import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const styles = theme => ({
  footer: {
    padding: theme.spacing.unit * 6,
  },
  email: {
    display: 'flex',
    flexDirection: 'row',
  }
})

const Footer = ({classes, t}) => (
  <footer className={classes.footer}>
    <Typography variant="h6" align="center" gutterBottom>
    {t('footer.header')}
    </Typography>
    <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
      {t('footer.line1')}
      <br />
      {t('footer.line2')}
      <br />
      {t('footer.line3')}<b>ondrej@runschool.eu</b>
    </Typography>
  </footer>
)

export default compose(
  withStyles(styles),
  withTranslation(),
)(Footer)