import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import globalTheme from "global/theme"
import Typography from '@material-ui/core/Typography';
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import { withStyles } from '@material-ui/core/styles'
import FreeBanner from './FreeBanner'

const styles = theme => ({
  layout: {
    backgroundColor: globalTheme.color.newLight,
    // padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 3}px`,
    padding: `0 0 ${theme.spacing.unit * 3}px`,
  },
  heroContent: {
    width: 'auto',

    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(900 + theme.spacing.unit * 3 * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  cardHeader: {
    backgroundColor: globalTheme.color.primary,
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing.unit * 2,
  },
  cardActions: {
    [theme.breakpoints.up('sm')]: {
      paddingBottom: theme.spacing.unit * 2,
    },
  },
});


const tiers = (t) => {
return [
    {
      title: t('pricing.basic'),
      price: '70',
      description: [
        t('pricing.row1'),
        t('pricing.row2'),
        '\b',
      ],
      buttonText: 'Get started',
      buttonVariant: 'contained',
    },
    {
      title: 'Pro',
      price: '100',
      description: [
        t('pricing.row1'),
        t('pricing.row2'),
        t('pricing.row3'),
      ],
      buttonText: 'Contact us',
      buttonVariant: 'outlined',
    },
  ]
}

const Cards = ({classes , t}) => (
  <div className={classes.layout}>
  <FreeBanner />
  <div className={classes.heroContent}>
  <Grid container spacing={40} alignItems="flex-end">
  {tiers(t).map(tier => (
    // Enterprise card is full width at sm breakpoint
    <Grid item key={tier.title} xs={12} sm={tier.title === 'Pro' ? 12 : 6} md={6}>
      <Card>
        <CardHeader
          title={tier.title}
          subheader={tier.subheader}
          titleTypographyProps={{ align: 'center' }}
          subheaderTypographyProps={{ align: 'center' }}
          className={classes.cardHeader}
        />
        <CardContent>
          <div className={classes.cardPricing}>
            <Typography component="h2" variant="h3" color="textPrimary">
              €{tier.price}
            </Typography>
            <Typography variant="h6" color="textSecondary">
              /{t('pricing.month')}
            </Typography>
          </div>
          {tier.description.map(line => (
            <Typography variant="subtitle1" align="center" key={line}>
              {line}
            </Typography>
          ))}
        </CardContent>
        {/* <CardActions className={classes.cardActions}>
          <Button fullWidth variant={tier.buttonVariant} color="primary">
            {tier.buttonText}
          </Button>
        </CardActions> */}
      </Card>
    </Grid>
  ))}
</Grid>
</div>
</div>
)

export default compose(
  withStyles(styles),
  withTranslation()
)(Cards)
