import React from 'react'
import Button from '@material-ui/core/Button'
import { compose } from 'recompose'
import { withStyles } from '@material-ui/core/styles'
import { withTranslation } from 'react-i18next'

const styles = () => ({
  main: {
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: '0 5rem 0 0'
  },
})

const LanguageBar = ({ classes, i18n }) => (
  <div className={classes.main}>
      <Button onClick={() => i18n.changeLanguage('en')}>en</Button>
      <Button onClick={() => i18n.changeLanguage('cs')}>cs</Button>
  </div>
)

export default compose(
  withStyles(styles),
  withTranslation()
 )(LanguageBar)
