// @flow
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: `rgba(0, 145, 213, .2)`,
      main: `rgba(0, 145, 213)`,
      dark: "#1C4E80",
    },
  },
});

export default theme