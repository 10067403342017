// @flow
import React from "react"
import ReactDOM from "react-dom"
import FontFaceObserver from "fontfaceobserver"
import AppComponent from "./AppComponent"
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from "global/mui-theme"
// import { I18nextProvider } from 'react-i18next';
import 'global/i18n'

const fontObserver = new FontFaceObserver("Rubik", {})
fontObserver.load().then(() => {
  const body = document.body
  if (body) {
    body.classList.add("fontLoaded")
  }
})

  ReactDOM.render(
    <MuiThemeProvider theme={theme}>
      <AppComponent />
    </MuiThemeProvider>,
    document.getElementById('root')
  )
